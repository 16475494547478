.explore-container {
  margin-top: 25px;
}
.dob-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.fast-report-holder {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 8px 8px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
}

.fast-report-holder-mobile {
  margin-left: auto;
}

.fast-report-holder:hover {
  background-color: #0d1333;
}

.fast-report-holder > svg {
  width: 20px;
  height: 20px;
  color: #fff;
}

.waiting-phase {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.mobile-waiting-phase {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: calc((9 / 16) * (100vh - 160px));
  height: calc(100vh - 160px);
  margin-left: auto;
  margin-right: auto;
}

.waiting-phase-containers-holder {
  width: 80%;
  display: flex;
  margin: auto;
  gap: 10px;
  /* height: calc(100vh - 160px); */
  height: calc(100vh - 200px);
}

.overlapping-interests-container {
  border-radius: 10px;
  background-color: var(--green);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 5px auto;
}
.overlapping-interests-container > p {
  margin: 0;
  padding: 0;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.overlapping-interests-container > p > strong {
  font-weight: bold;
}

.swap-mobile-view-btns-holder {
  display: flex;
  width: calc(80% - 100px);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}

.switch {
  position: relative;
  display: inline-block;
  width: 66px;
  height: 40px;
  margin-bottom: 0px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--blue);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--blue);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 40px;
}

.slider.round:before {
  border-radius: 50%;
}

.no-swap-button > div {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.mobile-view-toggler {
  margin-left: auto;
}
.ice-breaker-icon-container {
  margin-top: 5px;
  border-radius: 10px;
  background: var(--green);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  /* position:absolute; */
  left: 10px;
  z-index: 900;
  bottom: 10px;
}
/* .ice-breaker-icon-container svg {
  animation: spin 5s linear infinite;
} */

.calling-status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: bold;
  height: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.swap-button-holder {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.swap-button-holder-mobile {
  position: static;
  left: auto;
  transform: translateX(0%);
}

.toggler-btns {
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.mobile-view-on-toggler {
  color: var(--green);
}

.mobile-view-off-toggler {
  color: #ccc;
}

.swap-button-holder-mobile .swap-button {
  padding: 10px 20px;
}

.swap-button {
  border-radius: 10px;
  background-color: var(--green) !important;
  color: #fff;
  border: none;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  gap: 10px !important;
  padding: 10px 40px;
  align-items: center;
  cursor: pointer !important;
  margin: 0%;
}

.swap-button:disabled {
  opacity: 0.5;
}
.swap-button:hover {
  background-color: var(--greenHover) !important;
}

.swap-button > img {
  width: 16px;
  height: 16px;
}

.actions-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 10px 0px;
  gap: 20px;
}
.actions-container > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--blue);
  cursor: pointer;
}
.actions-container > div:hover {
  background-color: var(--blueHover);
}
.actions-container > div > img {
  height: 70%;
}
.actions-container > div:hover {
  opacity: 0.7;
}
.empty-action {
  border: 0px !important;
  background-color: transparent !important;
}
.foreign-username-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.local-video-container {
  width: 45%;
  aspect-ratio: 16 / 9;
  background-color: #222;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  padding: 0%;
  position: relative;
}
.foreign-username-avatar p {
  z-index: 99;
  font-weight: 700;
  text-align: center;
}
.local-video-container-username {
  display: flex;
  justify-content: center; /* Center children horizontally */
  height: 10%;
  width: 100%;
  position: absolute;
  align-self: flex-start;
  align-items: center;
}
/* .local-video-container-username.searching{
  height:100%;
}  */

.local-video-container-username > p {
  text-align: center;
  z-index: 5;
  position: sticky;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.remote-video-container {
  width: 100%;
  max-width: 480px;
  aspect-ratio: 16 / 9;
  background-color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
}

.local-video,
.remote-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scaleX(-1);
}

.camera-permission {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
}

.camera-permission > div {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.camera-permission > div > p {
  text-align: center;
  width: 100%;
  font-size: 22px;
  padding-left: 20px;
  padding-right: 20px;
}

.camera-permission-mobile > div > p {
  font-size: 12px !important;
}

.camera-permission button {
  margin-top: 1rem;
  padding: 10px 20px;
  background-color: var(--green);
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  z-index: 5;
}

.camera-permission-mobile button {
  margin-top: 0;
}

.camera-permission button:hover {
  background-color: var(--greenHover);
}

.searching-phase-container {
  display: flex;
  flex-direction: column;
  background-color: #222;
  width: 45%;
}

.searching-phase-searching {
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.remote-video-loader-holder {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.searching-phase-searching > p {
  font-size: 18px;
}

.instructions {
  display: flex;
  flex-direction: column;
  background-color: #222;
  width: 45%;
  position: relative;
}

.instructions-text {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.instructions-text > p {
  text-align: center;
  font-size: 22px;
  width: 100%;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: auto;
  margin-bottom: auto;
}

.instructions-text-mobile > p {
  font-size: 12px;
}

.instructions-kayber-logo {
  display: flex;
  bottom: 0;
  justify-content: flex-end;
  flex-direction: column;
  opacity: 0.7;
  position: absolute;
}

.instructions-kayber-logo > img {
  margin-left: 5px;
  margin-bottom: 5px;
  height: 30px;
}

.instructions-kayber-logo-mobile > img {
  margin-left: 5px;
  margin-bottom: 5px;
  height: 15px;
}

.foreign-video-kayber-logo {
  display: flex;
  height: 20%;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 30%;
  opacity: 0.7;
}

.foreign-video-kayber-logo > img {
  margin-left: 5px;
  margin-bottom: 10px;
  width: 90px;
}

.remote-video-loader {
  width: 100px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-image: url("../images/loading.png");
  background-size: cover;
  background-position: center;
  /* background-color: green; */
  animation: l5 2s linear infinite;
}
.remote-video-loader-mobile {
  width: 50px;
}
@keyframes l5 {
  from {
    transform: rotate(0deg); /* Start at 0 degrees */
  }
  to {
    transform: rotate(360deg); /* End at 360 degrees (1 turn) */
  }
}

.dob-popup {
  background-color: #0d1333;
  padding: 3% 3%;
  border-radius: 10px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  position: relative;
  z-index: 50 !important;
}

.dob-popup > h2 {
  font-size: 18px;
  text-align: center;
}
.dob-popup > p {
  margin-top: 30px;
  text-align: center;
}

.popup-continue-button-holder {
  width: 30%;
  margin: auto;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-continue-button-holder button:disabled {
  opacity: 0.5; /* Makes the button partially opaque */
  cursor: not-allowed; /* Changes the cursor to indicate it's not clickable */
}

.popup-continue-button-holder button:disabled:hover {
  background-color: var(--green);
}

.popup-form-content {
  display: flex;
  gap: 1rem;
}

.dob-popup input,
.dob-popup select {
  padding: 0.5rem;
  background-color: #0d1333;
  color: #fff;
  border-radius: 10px;
}

.dob-popup input:focus {
  border: 1px solid #0cbf0c !important;
}

.dob-popup-select {
  border: 1px solid #fff !important;
}

.dob-popup button {
  padding: 10px 30px;
  background-color: var(--green);
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.dob-popup button:hover {
  background-color: var(--greenHover);
}

/* @supports (-moz-appearance:none) {
  .browsertest {
    height: 296px;
    width: 296px; 
  }
}

@supports (transform: translateY(-50%)){
  .browsertest {
    max-height: 150px;
    max-width: 150px; 
  }
} */

.canvas {
  position: absolute;
  text-align: center;
  z-index: 8;
  transform: rotateY(180deg);
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.foreign-avatar-container {
  position: relative;
  width: 34.6px;
  height: 45px;
  margin-right: 5px;
}

.foreign-avatar-container > img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.report-popup {
  background-color: #0d1333;
  width: 45%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.report-popup > h2 {
  margin-top: 30px;
  font-size: 18px;
  font-weight: bold;
}

.report-popup > form {
  width: 90%;
  margin-top: 10px;
  margin-bottom: 50px;
}

.report-submit-button-holder {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-submit-btn {
  padding: 12px 35px;
  background-color: var(--green);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  align-self: center;
  text-align: center;
}

.report-submit-btn:hover {
  background-color: var(--greenHover);
}

.mobile-view-holder {
  flex-wrap: wrap-reverse;
  position: relative;
  width: 100%;
  height: 100%;
}

.overlapping-interests-mobile-view-holder {
  height: calc(100vh - 210px);
}

.actions-mobile-view {
  /* height: auto !important; */
  transform: translateY(-10px);
  position: absolute;
  top: 0;
  right: 0 !important;
  display: flex;
  width: 55px !important;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  margin-right: 5px;
  margin-bottom: auto;
  height: 50%;
}

.actions-mobile-view > div {
  height: 25px;
  width: 25px;
  background-color: rgba(8, 218, 218, 0.5) !important;
}
.actions-mobile-view > div:hover {
  opacity: 0.5 !important;
}
.overlapping-interests-mobile-view {
  width: 100%;
  margin: 5px auto;
}
.mobile-view-holder > .local-video-container {
  width: 100%;
}

.mobile-view-holder > .instructions {
  width: 100%;
}

.mobile-view-holder .instructions {
  height: 50% !important;
}

.mobile-view-holder .local-video-container {
  height: 50% !important;
}

.swap-mobile-view-btns-mobile-view {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.swipe-image {
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.end-of-call-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.end-of-call-popup.fade-out {
  opacity: 0;
  transform: translateX(-100%);
}

.end-of-call-popup-overlay {
  background-color: white;
  width: 35%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 0px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
  border-radius: 5px;
  color: #0d1333;
}

.end-of-call-popup-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.end-of-call-popup-close {
  display: flex;
  justify-content: flex-end;
  background-color: red;
  right: 10px;
}
.end-of-call-popup-close img {
  width: 30px;
  height: 30px;
  cursor: pointer;
  object-fit: contain;
}
.end-of-call-popup-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-top: 30px;
}
.end-of-call-popup-content-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.end-of-call-popup-content-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.end-of-call-popup-content-buttons .flag-action,
.end-of-call-popup-content-buttons .like-action,
.end-of-call-popup-content-buttons .dislike-action,
.end-of-call-popup-content-buttons .friend-action {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--green);
  cursor: pointer;
}
.flag-action:hover {
  background-color: var(--greenHover);
}
.end-of-call-popup-content-buttons .flag-action img {
  width: 20px;
  height: 20px;
}
.end-of-call-popup-content-buttons .like-action img,
.end-of-call-popup-content-buttons .dislike-action img,
.end-of-call-popup-content-buttons .friend-action img {
  width: 20px;
  height: 20px;
}

.end-of-call-popup-content-buttons .like-action img {
  width: 20px;
  height: 20px;
}

.end-of-call-countdown {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
}

@media (max-width: 950px) {
  /* .waiting-phase-containers-holder{
    flex-wrap: wrap-reverse;
    width: 95%;
    margin: auto;
    position: relative;
    height: calc(100vh - 160px); 
  }
  .overlapping-interests-container{
    width: 95%;
    margin: auto;
  }

  .waiting-phase-containers-holder>div{
    width: 100%;
  } */

  /* .actions-container {
    position: absolute;
    right: 5px !important;
    display: flex;
    width: 55px !important;
    justify-content: start;
    align-items: flex-end;
    flex-direction: column;
    height: calc(50vh - 160px) !important;
  }
  
  .actions-container>div{
    height: 35px;
    width: 35px;
  } */
  /* .swap-button-holder{
    width: 100%;
    justify-content: center;
    align-items: center;
  } */
}

/*Explore Confirmation Component */
/* Root gradient settings */

:root {
  --gradient-start: hsl(270, 70%, 90%);
  --gradient-end: hsl(310, 70%, 90%);
}
.progress-container {
  position: relative;
  width: 100%;
}
.progress-bar {
  height: 15px;
  border-radius: 30px;
  background: linear-gradient(90deg, red, yellow, green, var(--green));
}
.progress-container.mobile-view .progress-bar {
  height: 10px;
}

.confirmation-container {
  height: 100%;
  padding: 10px 10px;
  background: linear-gradient(
    135deg,
    var(--gradient-start),
    var(--gradient-end)
  );
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  max-width: 100%; /* Ensure it doesn't overflow */
  box-sizing: border-box;
  /* animation: gradientShift 4s ease infinite; */
  transition: background 4s ease-in;
}

.confirmation-container-mobile {
  gap: 0px;
  justify-content: space-evenly;
}
.voteText {
  position: absolute;
  top: -20px;
  transform: translateY(-0%);
  font-size: 13px;
  color: #fff;
  transition: left 1s linear;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  text-wrap: wrap;
  font-weight: 700;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* left: 50%;
  transform: translateX(-50%); */
}
.voteText.mobile-view {
  width: 50px;
  height: 50px;
  font-size: 12px;
  font-weight: 500;
}

@keyframes gradientShift {
  0% {
    background: linear-gradient(
      135deg,
      var(--gradient-start),
      var(--gradient-end)
    );
  }
  50% {
    background: linear-gradient(
      135deg,
      var(--gradient-end),
      var(--gradient-start)
    );
  }
  100% {
    background: linear-gradient(
      135deg,
      var(--gradient-start),
      var(--gradient-end)
    );
  }
}

/* .confirmation-seconds {
  font-size: 12px;
  color: #202020;
} */
.countdown-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}

.confirmation-seconds {
  position: relative;
  width: 80px;
  height: 80px;
}

.confirmation-seconds.mobile-view {
  width: 60px;
  height: 60px;
}
.confirmation-seconds.mobile-view > .countdown-text {
  font-size: 14px;
}
.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.confirmation-container > .public-profile-avatar-container {
  width: 80px !important;
  height: 80px !important;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.confirmation-container > .public-profile-avatar-container.mobile-view {
  width: 50px !important;
  height: 50px !important;
}

.confirmation-user-info {
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
  color: #fff;
}
.confirmation-user-info.mobile-view {
  margin-bottom: 5px;
  margin-top: 5px;
}
.confirmation-user-info.mobile-view span {
  font-size: 16px;
}

.confirmation-user-info .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  margin: 0 10px;
}
.username-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
}
.username-container span {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

.confirmation-user-info h2 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}

.confirmation-user-info p {
  font-size: 15px;
  margin: 0.25rem 0;
}

.confirmation-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 100%;
}
.confirmation-button-container > .confirm-btn {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.confirmation-button-container .username {
  color: var(--green);
  font-size: 13px;
}
.confirmation-button-container button {
  padding: 10px 15px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  transition: all 0.2s ease;
}
.confirmation-button-container button.mobile-view {
  padding: 10px 10px;
}
.confirmation-button-container button.mobile-view img {
  width: 15px;
  height: 15px;
}

.confirmation-button-container button img {
  width: 25px;
  height: 25px;
}

.confirmation-button-container button span {
  font-weight: bold;
}

.confirmation-cancel-btn {
  background: red;
  color: #fff;
}

.confirmation-cancel-btn:hover {
  background: rgb(218, 2, 2);
}

.confirmation-join-btn {
  background: #22c55e;
  color: white;
  cursor: pointer;
}
.confirmation-join-btn:disabled {
  background: rgb(34, 197, 94);
  color: white;
  opacity: 0.5;
  cursor: not-allowed;
}

.confirmation-join-btn:hover {
  background: #16a34a;
}

.ice-break-popup {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4455;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ice-break-popup-overlay {
  display: flex;
  align-items: center;
  width: 35%;
  background: #202336;
  margin: auto;
  margin-top: 20px;
  transition: all 1s ease-in;
  border-radius: 5px;
  justify-content: center;
  flex-direction: column;
  color: white;
  padding-bottom: 20px;
  max-height: 70%;
  overflow: hidden;
  margin: auto;
}
.ice-break-header {
  position: relative;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
}
.ice-break-header h5 {
  font-weight: 800;
  text-align: center;
  font-size: 20px;
}

.ice-break-close-btn {
  width: 25px;
  height: 25px;
  border: none;
  position: absolute;
  background: rgba(232, 8, 8, 0.6);
  right: 10px;
  top: 0;
  border-radius: 4px;
  cursor: pointer;
}
.ice-break-close-btn:active,
.ice-break-close-btn:focus-visible {
  border: none;
}
.ice-break-close-btn img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.ice-break-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.ice-break-item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 30px;
  margin-top: 20px;
}
.ice-break-item label {
  color: #ffffffa1;
  font-size: 14px;
}
.ice-break-item:first {
  margin-top: 10px;
}

.ice-break-item button {
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  flex: 0 0 auto;
  line-height: 2;
  border: none;
  background: var(--green);
  color: white;
}

.ice-break-item button.secondary-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ice-breaker-container-explore {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  background: #cccc03;
  border-radius: 10px;
  width: 100%;
  padding: 2px 20px;
  margin-bottom: 5px;
}
.ice-breaker-container-explore label {
  margin-bottom: 0px;
}
.overlapping-icebreaker-container {
  width: 70%;
}

.overlapping-icebreaker-mobile-view {
  width: 100%;
}
.interests-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.interest-badge {
  padding: 8px 15px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  flex: 0 0 auto;
  line-height: 2;
}
.ice-break-item .interest-badge {
  border-radius: 20px;
}
.interest-input-container {
  display: flex;
  justify-content: center;
  background: #ffffff;
  border: none;
  position: relative;
  align-items: center;
  border-radius: 20px;
  padding: 0px 5px;
  width: 180px;
}
.interest-input-container input {
  background: transparent;
  border: none;
  border-radius: unset;
}
.interest-input-container input:focus {
  border: none;
  outline: none !important;
}
.interest-input-container button {
  border: none;
  width: 30px;
  height: 30px;
  padding: 5px;
  cursor: pointer;
  background: var(--green);
  border-radius: 50%;
}
.interest-input-container button img {
  width: 100%;
  height: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 768px) {
  .confirmation-avatar {
    width: 50px;
    height: 50px;
  }
  .ice-break-popup-overlay {
    width: 50%;
  }

  .confirmation-user-info h2 {
    font-size: 1.1rem;
  }

  .confirmation-user-info p {
    font-size: 1rem;
  }

  .confirmation-button-container {
    gap: 1rem;
    flex-direction: row;
    margin-top: 0.5rem;
  }

  .confirmation-button-container > button {
    font-size: 0.9rem;
    padding: 10px 10px;
  }
  .confirmation-button-container > button > img {
    width: 15px;
    height: 15px;
  }
  .end-of-call-popup-overlay {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .confirmation-avatar {
    width: 40px;
    height: 40px;
  }
  .ice-break-popup-overlay {
    width: 100%;
  }

  .confirmation-user-info h2 {
    font-size: 1rem;
  }

  .confirmation-user-info p {
    font-size: 0.9rem;
  }

  .confirmation-button-container {
    gap: 0.25rem;
  }

  .confirmation-button-container > button {
    font-size: 0.85rem;
  }
  .end-of-call-popup-overlay {
    width: 80%;
  }
}

.video-calling-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-call-loading-popup {
  background: rgb(22, 163, 74);
  width: 400px;
  margin-left: 20%;
  padding: 30px 30px 50px;
  position: relative;
  border-radius: 5px;
  z-index: 999;
}

.video-call-loading-header {
  font-size: 18px;
  color: #000;
  margin-top: 30px;
  text-align: center;
  font-weight: bold;
}

.video-call-loading-user-count > span {
  font-size: 16px;
  color: var(--blue);
  text-align: center;
  font-weight: bold;
}
.video-call-loading-user-count {
  font-size: 16px;
  color: #000;
  text-align: center;
}
.trending-interests-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 70%;
  margin: 0px auto;
}
.trending-interests-container > p {
  white-space: nowrap;
  font-weight: bold;
  font-size: 17px;
}
.trending-interests {
  position: relative;
  width: 80%;
}

.trending-interest-holder {
  width: 100%;
  display: flex;
  gap: 10px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.trending-interest-holder::-webkit-scrollbar {
  display: none;
}
.trending-interest-item {
  padding: 4px 15px;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  flex: 0 0 auto;
  line-height: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  font-weight: bold;
  text-transform: capitalize;
}

.trending-interest-included {
  background-color: var(--green) !important;
  border: none;
  color: #fff;
}

.trending-interest-included:hover {
  background-color: var(--greenHover);
}

.trending-interest-item > span {
  font-weight: normal;
}

.trending-interests .controls {
  top: 0px !important;
  display: none;
}

.trending-interests .controls--back {
  left: -2px !important;
}

.trending-interests .controls--next {
  right: -2px !important;
}

.trending-interests:hover .controls {
  display: inline;
}

@media (max-width: 950px) {
  .trending-interests .controls {
    display: none;
  }

  .video-call-loading-popup {
    width: 300px;
    margin: auto;
  }
}

.auth-request-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.auth-request-popup-overlay {
  background-color: #0d1333;
  width: 45%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
}

.auth-request-popup-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}
.auth-request-popup-content p {
  text-align: center;
  color: #fff;
}
.auth-request-popup-btns-holder {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.auth-request-popup-login-btn {
  color: white;
  background-color: transparent;
  padding: 10px 20px;
  border: 1px dashed white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-decoration: none;
}
.auth-request-popup-login-btn:hover {
  opacity: 0.7;
  color: white;
}
.auth-request-popup-btns-holder .auth-request-popup-signup-btn {
  padding: 10px 20px;
  background-color: var(--green);
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}

.auth-request-popup-btns-holder .auth-request-popup-signup-btn:hover {
  background-color: var(--greenHover);
  underline: none;
}

@media (max-width: 768px) {
  .auth-request-popup-overlay {
    width: 90%;
  }
  .auth-request-popup-content {
    margin-top: 30px;
  }
}
.animated_arrow {
  position: absolute;
  height: unset;
  width: 100%;
  object-fit: contain;
  top: 10px;
  left: 20px;
  pointer-events: none;
}
.animated_arrow_mobile {
  top: 10px;
  left: 0px;
}
