@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap);

:root {
  --blue: #08dada;
  --green: #00dd00;
  --blueHover: #07a9a9;
  --greenHover: #00ab00;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
em-emoji-picker {
  --rgb-accent: 8, 218, 218;
  --rgb-background: 13, 19, 51;
  --rgb-color: 255, 255, 255;
  --background-rgb: 8, 218, 218;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Light.otf") format("opentype");
  font-weight: 100; /* Regular weight */
  font-style: normal;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Regular.otf") format("opentype");
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

/* Define the bold weight font */
@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Bold.otf") format("opentype");
  font-weight: 700; /* Bold weight */
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0%;
  padding: 0%;
}

img {
  -webkit-user-drag: none;
}

html {
  scroll-behavior: smooth;
}

a {
  color: var(--blue);
}

a:hover {
  color: var(--blueHover);
}

body {
  font-family: "Inter", sans-serif !important;
  background-color: #202336 !important;
  margin: 0;
  font-family: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: whitesmoke;
  text-align: left;
  flex: 1 0 auto;
}

input:focus {
  outline: 1px solid var(--green) !important;
}
input.error {
  border: 1px solid red !important;
}

.inner-app {
  display: flex;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

.inner-app-content {
  margin-left: 20%;
  width: 80%;
  box-sizing: border-box;
  transition: margin-left 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);
}

.inner-app-content.full-width {
  margin-left: 0;
  width: 100%;
}

.alert {
  color: red;
}

.main-content {
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  flex: 1 0 auto;
  min-height: 100vh;
}

.reset-password-form {
  margin-top: 50px !important;
}

.reset-password-form-holder {
  width: 40%;
  margin: auto;
}

@media (max-width: 768px) {
  .main-content {
    padding: 5px 10px;
  }
  .full-width {
    width: 100% !important;
  }
}

.verificationbutton:hover {
  margin-top: 20px;
}
.verificationbutton:active {
  margin-top: 20px;
}
.verificationbutton {
  margin-top: 20px;
}

.signup-disclaimer {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 10px;
}

.post-container-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures elements stay in place */
  min-height: 100%; /* Ensures it takes full height */
}

.image-preview-container {
  width: 100%; /* Take full width of the container */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevents any unwanted overflow */
  padding: 10px;
}

.image-preview {
  width: 100%; /* Makes the image take up the full width */
  height: auto; /* Allows height to scale dynamically */
  object-fit: contain; /* Ensures the full image is visible without distortion */
  border-radius: 10px; /* Optional: Add rounded corners */
}

.image-upload-input {
  display: none;
}

.image-upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ddd;
  margin-top: 10px;
}

.image-upload-button {
  background-color: var(--green);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  max-width: 200px;
  cursor: pointer;
  margin-top: 10px;
}

.image-upload-button:hover {
  background-color: var(--greenHover);
}

.image-preview-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.image-preview {
  max-width: 100%;
  border-radius: 10px;
  display: block; /* Ensures correct rendering */
}
video.image-preview {
  object-fit: cover;
}
.video-preview {
  position: relative;
  margin-bottom: 50px;
}
.video-play,
.video-play-pause {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(
    --green
  ); /* Optional: Background overlay for better visibility */
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 16px;
  z-index: 10; /* Ensure it appears above the video */
  outline: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
}
.video-preview:hover > .video-play {
  transform: translate(-50%, -50%) scale(1.2); /* Scale up the button */
  background-color: var(--green);
}
.video-play-pause {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.video-preview:hover > .video-play-pause {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.video-backward,
.video-forward {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 16px;
  z-index: 10;
  outline: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
  opacity: 0;
}
.video-preview:hover > .video-play-pause,
.video-preview:hover > .video-forward,
.video-preview:hover > .video-backward {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.video-backward {
  left: 10%;
}
.video-forward {
  right: 10%;
}
.video-progress-container {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 10px;
}
#progress-bar:focus {
  outline: none !important;
}
#progress-bar {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  width: 100%;
  height: 3px; /* Thickness of the track */
  background: transparent;
  border-radius: 5px;
  outline: none; /* Remove focus outline */
  transition: background 0.3s ease-in-out;
  border: none !important;
}

/* #progress-bar:hover {
  background: #aaa; 
} */
:root {
  --progress-value: 0%;
}
/* Style the thumb */
#progress-bar::-webkit-slider-thumb,
.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  background: var(--green);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(3, 229, 41, 0.2); /* Add shadow */
  transition: transform 0.2s ease-in-out;
  margin-top: -3px;
}

#progress-bar::-webkit-slider-thumb:hover,
.volume-slider::-webkit-slider-thumb {
  transform: scale(1.4); /* Enlarge thumb on hover */
}

/* Style the track */
#progress-bar::-webkit-slider-runnable-track,
.volume-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  background: linear-gradient(
    to right,
    var(--blue) var(--progress-value),
    var(--green) var(--progress-value)
  ); /* Progress gradient */
  border-radius: 5px;
}

/* For Firefox */
#progress-bar::-moz-range-thumb,
.volume-slider::-moz-range-thumb {
  width: 10px;
  height: 1px;
  background: var(--green);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

#progress-bar::-moz-range-track,
.volume-slider::-moz-range-track {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    var(--blue) var(--progress-value),
    var(--gray) var(--progress-value)
  );
}

#progress-bar::-moz-range-progress,
.volume-slider::-moz-range-progress {
  background: var(--green); /* Progress color in Firefox */
}

.video-play,
.video-play-pause,
.video-forward,
.video-backward,
.volume-control svg {
  filter: drop-shadow(0px 0px 4px black);
}

#progress-bar,
.volume-slider {
  filter: drop-shadow(0px 0px 4px black);
}

.volume-control {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
input.volume-slider {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  width: 100%;
  height: 3px; /* Thickness of the track */
  background: transparent;
  border-radius: 5px;
  outline: 1px solid transparent !important; /* Remove focus outline */
  transition: background 0.3s ease-in-out;
  border: none !important;
}
input.volume-slider:hover {
  outline: none !important;
}
.volume-slider:hover {
  border: none;
}

.image-upload-section.dragging {
  border: 2px dashed var(--blue);
  background-color: rgba(8, 218, 218, 0.1);
}

.loginpagebutton {
  color: rgb(28, 28, 28);
  background-color: var(--green);
  color: white;
  font-size: 16px;
  border: 1px solid white;
  border-radius: 5px;
  border-width: 0px;
  margin-bottom: 10px;
  width: 60%;
  padding: 9px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginpagebutton:hover {
  background-color: hsl(120, 100%, 34%);
  margin-bottom: 10px;
}
.loginpagebutton:active {
  background-color: hsl(120, 100%, 34%);
  margin-bottom: 10px;
}

.login-button {
  background-color: var(--green);
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  padding: 2px 20px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
}

.login-button:hover {
  background-color: var(--greenHover);
}

.phone-input {
  --PhoneInputCountryFlag-borderColor: transparent;
  --PhoneInputCountryFlag-borderColor--focus: transparent;
  --PhoneInputCountrySelectArrow-color: white;
  --PhoneInputCountrySelectArrow-width: 0.5em;
  --PhoneInputCountrySelectArrow-borderWidth: 2px;
  --PhoneInputCountrySelectArrow-opacity: 0.8;
}
/* Styling the input box and its border */
.PhoneInput {
  border-radius: 5px !important;
  width: 100%;
}

.phone-input .PhoneInputInput {
  color: #6c757d; /* Change the text color */
  border: 0px;
  font-size: 16px;
  border-radius: 5px !important;
}

.phone-input:focus,
.PhoneInputInput:focus {
  border: none !important;
  outline: none !important;
}

.phone-input .PhoneInputCountry {
  background-color: var(--blue); /* Change to your desired background color */
  padding: 13px 15px;
  border-radius: 5px !important;
}

.phone-input .PhoneInputCountryIcon {
  background-color: var(--blue);
}

.phone-input .PhoneInputCountrySelect {
  background-color: transparent;
  border: none;
}

.phone-input .PhoneInputCountryIcon {
  background-color: "black";
  border: 0px solid var(--blue);
  outline: none;
}

.phone-input .PhoneInputInput:focus {
  border-color: var(--blue);
  outline: none;
}

/* form:invalid .loginpagebutton{
pointer-events: none;
opacity: 0.25;
} */

.input {
  border: 1px solid white;
  border-radius: 10px;
}

.loginpagebutton:disabled {
  opacity: 0.25;
}

/* input:focus {
  outline: 1px solid var(--green) !important;
}
input.error {
  border: 1px solid red !important;
} */

.login-form {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}

.login-button-holder {
  display: flex;
  flex-direction: column;
}

.login-form-row {
  margin: 0% !important;
}

.login-form-row {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.login-form-row > a {
  margin: 0% !important;
}

.login-form-row > input,
.login-form-row > div > input {
  margin: 0% !important;
  padding: 12px 10px !important;
  border-radius: 4px !important;
  width: 210px;
}

.nav-item {
  display: inline-block;
  margin: 0 7.5px;
  top: 50%;
}

/* Styles for screens smaller than 768px */
@media (max-width: 767px) {
  .nav-item {
    display: block;
    margin: 0 auto;
    top: 0;
  }
  .reset-password-form-holder {
    width: 90%;
  }
}

/* Styles for screens smaller than 576px */
@media (max-width: 575px) {
  .nav-item {
    display: block;
    margin: 0 auto;
    top: 0;
  }
}

/* Post page styles */
.post-container-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.page-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.page-title {
  color: white;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  margin-top: 10px;
}

.title-line-segment {
  width: 34px;
  height: 4px;
  border-radius: 12px;
  background-color: transparent;
  margin-top: 5px;
}
.post-line {
  width: 44%;
  height: 6px;
  margin-top: 0px;
  border-radius: 2px;
}

.char-count {
  width: 39%;
  margin-top: 2px;
}

.char-count > p {
  font-size: 14px;
  text-align: right;
}
.char-count span {
  font-weight: bold;
}

.bear-container {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  flex-shrink: 0;
  border-radius: 8px;
}

.post-bear-container {
  width: 10%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-right: 7px;
  flex-shrink: 0;
  border-radius: 8px;
}

.username-container {
  height: 30px;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: black;
  margin-left: 5px;
}

.text-input-container {
  display: flex;
  width: 45%;
  background-color: #fff;
  border-radius: 8px;
}

.username-input-container {
  display: flex;
  width: 80%;
  flex-direction: column;
  background-color: #fff;
  flex-grow: 1;
  border-radius: 4px;
}

.text-input {
  width: 100%;
  min-height: 100px;
  resize: vertical;
  padding: 5px;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
}

.text-input:focus {
  outline: none;
}

.textarea {
  font-family: inherit;
  font-size: inherit;
}

.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 60px;
  line-height: 20px;
  color: #000;
}

.textarea[contenteditable]:empty::before {
  content: "Enter your text here...";
  color: #a8a2a2;
}

.textarea:focus {
  border: none;
  outline: none;
}

.times-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 30px;
  width: 80%;
  margin: auto;
}

.times-button {
  background-color: var(--blue);
  border: none;
  border-radius: 6px;
  margin: 5px 5px;
  padding: 8px 25px;
  cursor: pointer;
  font-size: 14px;
  color: white;
}

.times-button.selected {
  background-color: rgb(10, 106, 201);
}

.post-button {
  background-color: var(--green);
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 35px;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  align-self: center;
}

.popup-title {
  font-size: 27px !important;
  font-weight: bolder !important;
}

.popup-title-container {
  margin-top: 5px !important;
  margin-bottom: 0px !important;
}

.post-button:hover {
  background-color: var(--greenHover);
}

.loader {
  width: 20px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#fff 0 0);
  background: var(--c) 0% 50%, var(--c) 50% 50%, var(--c) 100% 50%;
  background-size: 20% 100%;
  animation: l1 1s infinite linear;
}

@keyframes l1 {
  0% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }
  33% {
    background-size: 20% 10%, 20% 100%, 20% 100%;
  }
  50% {
    background-size: 20% 100%, 20% 10%, 20% 100%;
  }
  66% {
    background-size: 20% 100%, 20% 100%, 20% 10%;
  }
  100% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }
}

.special-loader-holder {
  width: 100%;
  margin: 20px 0;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.special-loader-holder > .loader {
  --c: no-repeat linear-gradient(#a6a6a6 0 0);
}

.loader-holder {
  width: 100%;
  height: 70vh;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.page-loader {
  width: 2%;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#fff 0 0);
  background: var(--c) 0% 50%, var(--c) 50% 50%, var(--c) 100% 50%;
  background-size: 20% 100%;
  animation: l1 1s infinite linear;
}
@keyframes l1 {
  0% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }
  33% {
    background-size: 20% 10%, 20% 100%, 20% 100%;
  }
  50% {
    background-size: 20% 100%, 20% 10%, 20% 100%;
  }
  66% {
    background-size: 20% 100%, 20% 100%, 20% 10%;
  }
  100% {
    background-size: 20% 100%, 20% 100%, 20% 100%;
  }
}

.delete-account-btns-holder {
  width: 70%;
  margin-top: 60px !important;
  margin-bottom: 60px !important;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.confirm-delete-account-btn {
  background-color: rgb(210, 20, 20);
  color: #fff;
  padding: 15px 12px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  font-size: 15px;
  border: none;
  width: 40%;
}

.cancel-delete-account-btn {
  background-color: var(--green);
  color: #fff;
  padding: 15px 15px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  font-size: 15px;
  border: none;
  width: 40%;
}

.cancel-delete-account-btn:hover {
  background-color: var(--greenHover);
}

.confirm-delete-account-btn:hover {
  background-color: rgb(169, 16, 16);
}

.banned-holder {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banned-title {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}
.banned-items {
  font-size: 17px;
  font-weight: bold;
}
.banned-items > span {
  font-weight: normal;
}
.banned-description {
  font-size: 17px;
  color: #fff;
  margin: 10px 0px;
}

@media screen and (max-width: 600px) {
  .banned-holder {
    width: 100%;
  }
  .navbar-item {
    margin-bottom: 10px;
  }
  .username-container {
    flex-direction: column;
    align-items: center;
  }
  .bear-container {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .times-container {
    width: 100% !important;
    margin: 10% 0% !important;
  }

  .avatar-container {
    position: relative;
    width: 40px;
    height: 50px;
    margin-top: -7.5px;
  }

  .avatar-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .text-input-container {
    width: 100%;
  }
  .bear-container {
    width: 15%;
  }
  .username-input-container {
    width: 85%;
  }
  .post-line {
    width: 97%;
  }
}
/* Post page styles end */

/* Custom CSS Styles */

.login-btn {
  text-transform: none;
  font-size: 15px;
  height: 35px;
  display: flex;
  align-items: center;
}

.login-signup-holder > a {
  text-decoration: none !important;
}

.login-signup-holder > a:hover {
  text-decoration: none !important;
}

.btn-icon {
  display: inline-block;
  margin-right: 5px;
}

.signup-container {
  border-left: 3px dotted white;
  display: inline-block;
  height: 35px;
  padding-left: 5px;
  margin-left: 5px;
}

.signup-btn {
  text-transform: none;
  font-size: 15px;
  height: 35px;
  display: flex;
  align-items: center;
}

.signup-form-row {
  margin: 10px 0px;
}

.signup-form-row > h5 {
  font-size: 15px;
  font-weight: bold;
  margin: 0%;
  color: #fff;
}
.signup-dob-select {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px 0px;
  width: 70%;
}

.signup-text {
  font-size: 35px;
  font-weight: bold;
}

.dob-select-label {
  font-size: 16px;
  margin-top: 5px;
}

.signup-dob-select input,
.signup-dob-select select {
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  border: 1px solid #fff;
  padding: 10px !important;
  height: 40px !important;
}

.signup-dob-select input:focus {
  border: 1px solid #0cbf0c !important;
}

.signup-dob-select-select {
  border: 1px solid #fff !important;
}

.signup-dob-select button {
  padding: 10px 30px;
  background-color: var(--green);
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.signup-dob-select button:hover {
  background-color: var(--greenHover);
}

.signup-form-row > a {
  font-size: 13px;
  color: var(--blue);
  margin-top: 15px;
}

.signup-form-row > input,
.signup-form-row > div > input {
  background-color: #fff;
  border: 1px solid #fff;
  padding: 12px 12px;
  border-radius: 5px;
  font-size: 14px !important ;
  margin: 5px 0px;
}

.verify-code-input {
  background-color: #fff;
  border: 1px solid #fff;
  padding: 12px 12px;
  border-radius: 5px;
  font-size: 14px !important ;
  margin: 5px 0px 20px;
}

.login-button-below-text > p > a {
  color: var(--blue) !important;
  font-weight: bold;
}

.verify-code-button {
  color: rgb(28, 28, 28);
  background-color: var(--green);
  color: white;
  font-size: 14px;
  border: 1px solid white;
  border-radius: 5px;
  border-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 40%;
  padding: 12px 20px;
  cursor: pointer;
}

.resend-code-button {
  background-color: var(--blue) !important;
}

.resend-code-button:hover {
  background-color: var(--blueHover) !important;
}

.verify-code-button:hover {
  background-color: hsl(120, 100%, 34%);
  margin-bottom: 10px;
}
.verify-code-button:active {
  background-color: hsl(120, 100%, 34%);
  margin-bottom: 10px;
}

.or-text {
  color: #dedede;
  text-align: center;
  font-weight: bold;
}

.requirement-row {
  gap: 15px;
  align-items: center;
  margin-top: 5px !important;
  margin-bottom: 10px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  display: flex;
}

.requirement-row > p {
  margin: 0% !important;
  font-size: 14px;
}

.toggle-password {
  position: absolute;
  right: 10px;
  z-index: 100;
  color: #6e6e6e;
  font-size: 20px;
  cursor: pointer;
}

.password-input-group {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
}

.markdown-editor {
  position: relative;
}
.markdown-editor .preview {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  overflow-y: auto;
  z-index: 100;
  resize: both;
  min-height: 60px;
  line-height: 20px;
  color: #000;
}
.markdown-editor .formatted-toolbar {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  gap: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
}
.preview-button {
  background-color: var(--green);
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 35px;
  cursor: pointer;
  font-size: 14px;
}

.markdown-editor-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.DraftEditor-root {
  color: black;
}
.toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  padding: 4px;
  z-index: 1;
  gap: 3px;
}
.toolbar .separator {
  border-left: 2px solid rgb(32, 32, 32);
  margin: auto 5px;
  height: 20px;
}
.toolbar button.active,
.toolbar label.active {
  font-weight: 900;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.DraftEditor-editorContainer pre {
  background-color: #f8f9fa;
  border-radius: 5px;
  border: 0.5px solid #ced4da;
  width: fit-content;
}
.DraftEditor-editorContainer pre code {
  background-color: #f8f9fa;
  border-radius: 5px;
  border: 0.5px solid #ced4da;
  width: fit-content;
}
.DraftEditor-editorContainer blockquote div:first-child {
  /* add line left  */
  border-left: 3px solid var(--blue);
  border-radius: 2px;
  padding-left: 5px;
  margin-left: 0px;
}
.preview-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.preview-images img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}
.preview-images img:hover {
  cursor: pointer;
}
.preview-images .preview {
  position: relative;
  border-radius: 5px;
  border: 1px solid #ced4da;
}
.preview .delete button {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  color: white;
  border: none;
  cursor: pointer;
  background-color: red;
  width: 20px;
  height: 20px;
}

.toolbar button,
.toolbar label {
  background-color: transparent;
  padding: 3px 8px;
  cursor: pointer;
  border: none;
  bottom: 0;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-timer-container {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4455;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post-timer-popup-overlay {
  display: flex;
  align-items: center;
  width: 35%;
  background: #202336;
  margin: auto;
  margin-top: 20px;
  transition: all 1s ease-in;
  border-radius: 5px;
  justify-content: center;
  flex-direction: column;
  color: white;
  padding-bottom: 20px;
  max-height: 70%;
  overflow: hidden;
  margin: auto;
}
.post-timer-header {
  position: relative;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
}
.post-timer-header h5 {
  font-weight: 800;
  text-align: center;
  font-size: 20px;
}

.post-timer-close-btn {
  width: 25px;
  height: 25px;
  border: none;
  position: absolute;
  background: rgba(232, 8, 8, 0.6);
  right: 10px;
  top: 0;
  border-radius: 4px;
  cursor: pointer;
}
.post-timer-close-btn:active,
.post-timer-close-btn:focus-visible {
  border: none;
}

.GifPickerReact.gpr-main {
  background-color: #0d1333 !important;
}
.GifPickerReact .gpr-category:hover,
.GifPickerReact .gpr-result-image:hover {
  box-shadow: 0 0 0 2px var(--blueHover) !important;
}
.GifPickerReact .gpr-search-container input.gpr-search {
  background-color: #0c1333;
}
.form-group .report-description {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
}

.price-range-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  flex-direction: column;
  width: 100%;
}
.price-range-holder h2 {
  font-weight: bold;
  margin-bottom: 10px;
}
.price-range-holder .dual-range {
  width: 100%;
}
.price-range-holder .range-values {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.price-range-holder .range-values span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.price-range-holder .range-values .kaybits-coin {
  width: 20px;
  height: 20px;
}
.categories-holder {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  flex-direction: column;
}
.categories-holder label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.categories-holder input[type="checkbox"] {
  width: unset;
}
.categories-holder input[type="checkbox"]:focus {
  outline: none !important;
}
.categories-holder input[type="checkbox"] {
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}
.categories-holder .checkmark {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #fff;
}
.categories-holder .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.categories-holder .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #202336;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.categories-holder input[type="checkbox"]:checked + .checkmark:after {
  display: block;
}

.categories-holder input[type="checkbox"]:checked + label {
  background-color: var(--green);
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
}
.categories-holder input[type="checkbox"]:checked + label:hover {
  background-color: var(--greenHover);
}

.accept-call-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accept-call-inner {
  background: #202336;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  max-width: 25%;
  max-height: fit-content;
  overflow: auto;
  width: 100%;
  padding-bottom: 20px;
}

.accept-call-header {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
.accept-call-close-icon {
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #fff;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accept-call-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.accept-call-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.accept-call-avatar img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.accept-call-username {
  font-size: 20px;
  font-weight: bold;
  color: white;
}
.accept-call-status {
  font-size: 16px;
  color: #fff;
}
.accept-call-action {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.accept-call-action button {
  padding: 5px 30px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.accept-call-action button.accept {
  background: var(--green);
  color: white;
}
.accept-call-action button:last-child {
  background: red;
  color: white;
}
@media screen and (min-width: 769px) and (max-width: 1300px) {
  .text-input-container {
    width: 60%;
  }

  .banned-holder {
    width: 70%;
  }

  .post-line {
    width: 59%;
  }

  .times-container {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .times-container {
    width: 50%;
  }
  .post-timer-popup-overlay {
    width: 80%;
  }
}

.label-container {
  margin-top: 20px;
  text-align: center;
}
